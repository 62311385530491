
html, body, #root {
    height: 100%;
    overflow: hidden;
    width: 100%;
}

.widget-nav .nav-link {
  color: #28a745; /* Bootstrap's 'success' color */
}

.widget-nav .nav-link.active {
  color: #fff;
  background-color: #28a745;
}